<template>
  <div class="home" @click="goToSelect">
    <div
      class="w-full h-[60vh] md:h-[500px] bg-gradient-to-t from-[#4e497c] via-[#5d5893] to-[#726da3] top-0 relative"
    >
      <div class="absolute inset-0 z-20 flex items-center justify-center">
        <img class="w-full h-full object-cover" src="../assets/dk_photo1.png" />
        <img
          class="absolute top-[70%] left-1/2 w-[30vw] max-w-[700px] transform -translate-x-1/2 -translate-y-1/2 z-30"
          src="../assets/dk_photo2.png"
        />
        <p
          class="font-gmarket flex flex-row-reverse px-4 md:px-8 text-white font-light text-[4vw] md:text-[25px] absolute top-4 md:top-8 right-4 md:right-8 z-40"
        >
          DKSH NOID
        </p>
      </div>
    </div>
    <div class="pt-20 md:pt-40 text-center">
      <p class="text-[10vw] md:text-[100px] text-black">
        <span class="font-gmarket font-bold">단국</span>
        <span class="font-gmarket font-light">네컷</span>
      </p>
      <p class="font-gmarket text-[4vw] md:text-[35px] text-black mt-2 md:mt-4">
        화면을 터치해주세요
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToSelect() {
      window.location.href = "/Select";
    },
  },
};
</script>
